<template>
  <div id="app">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/layouts/Header";
import Footer from "@/layouts/Footer";
export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    if (this._isMobile()) {
      // console.log("手机端");
      this.$store.state.isMobile = true
    } else {
      // console.log("pc端");
      this.$store.state.isMobile = false
    }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style";
</style>

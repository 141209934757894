<template>
  <div class="page-detail-container">
    <div class="page-detail-swiper-container">
      <swiper
        class="page-swiper-container"
        :options="swiperOptions"
        v-if="renderData"
      >
        <swiperSlide
          class="page-swiper-slide"
          v-for="(item, index) in renderData"
          :key="index"
        >
          <div
            class="swiper-show-img swiper-child-item"
            :style="{ backgroundImage: `url(${item.img})` }"
          ></div>
          <div class="swiper-text-info swiper-child-item">
            <div
              class="icon"
              :style="{ backgroundImage: `url(${item.icon})` }"
            ></div>
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </swiperSlide>
      </swiper>
      <div class="page-swiper-pagination" slot="pagination"></div>
      <div class="page-swiper-navigation">
        <div
          class="page-swiper-navigation--prev page-swiper-navigation--bts"
        ></div>
        <div
          class="page-swiper-navigation--next page-swiper-navigation--bts"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      datas: {
        live: [
          {
            img: require("@/assets/images/swiper/live/1-1.jpg"),
            icon: require("@/assets/images/swiper/live/1-2.jpg"),
            title: "专属直播间",
            content:
              "商家开启专属直播间，直播讲述没意见商品成色，与多个奢侈品同行线上贸易。"
          },
          {
            img: require("@/assets/images/swiper/live/2-1.jpg"),
            icon: require("@/assets/images/swiper/live/2-2.jpg"),
            title: "限时抢购",
            content: "用户了解商品详情后，长按”要了“三秒即可成交。"
          },
          {
            img: require("@/assets/images/swiper/live/3-1.jpg"),
            icon: require("@/assets/images/swiper/live/3-2.jpg"),
            title: "互动红包",
            content: "直播商家发放的红包可用于直播间成交商品现金抵扣。"
          },
          {
            img: require("@/assets/images/swiper/live/4-1.jpg"),
            icon: require("@/assets/images/swiper/live/4-2.jpg"),
            title: "直播比价",
            content:
              "直播间可进行现场比价互动，点击出价即可参与，所有商品价高者得。"
          }
        ],
        "price-compare": [
          {
            img: require("@/assets/images/swiper/price-compare/1-1.jpg"),
            icon: require("@/assets/images/swiper/price-compare/1-2.jpg"),
            title: "提前报名",
            content: "提前对心仪商品可报名预约，比价开始后优先显示。"
          },
          {
            img: require("@/assets/images/swiper/price-compare/2-1.jpg"),
            icon: require("@/assets/images/swiper/price-compare/2-2.jpg"),
            title: "互动红包",
            content: "商家比价场互动红包，可用于比价环节成交商品现金抵扣。"
          },
          {
            img: require("@/assets/images/swiper/price-compare/3-1.jpg"),
            icon: require("@/assets/images/swiper/price-compare/3-2.jpg"),
            title: "详情页面",
            content:
              "点击商品图片，即可进入详情页面，商品成色及瑕疵均有详细描述。"
          },
          {
            img: require("@/assets/images/swiper/price-compare/4-1.jpg"),
            icon: require("@/assets/images/swiper/price-compare/4-2.jpg"),
            title: "游戏模式比价",
            content:
              "比较开始后，报名以及出价被超越商品均有跳动红框提醒，未报名以及未出价商品均在此类商品下方，出价后位置将上升。"
          }
        ],
        payment: [
          {
            img: require("@/assets/images/swiper/payment/1-1.jpg"),
            icon: require("@/assets/images/swiper/payment/1-2.jpg"),
            title: "个人收银台",
            content:
              "成交商品归纳个人收银台，点击结算，输入收货地址，选择结算方式即可完成付款。"
          },
          {
            img: require("@/assets/images/swiper/payment/2-1.jpg"),
            icon: require("@/assets/images/swiper/payment/2-2.jpg"),
            title: "交易失败",
            content: "点击收银台右上角，即可查看交易失败商品"
          },
          {
            img: require("@/assets/images/swiper/payment/3-1.jpg"),
            icon: require("@/assets/images/swiper/payment/3-2.jpg"),
            title: "我的购买",
            content:
              "用户个人主页我的购买当中可显示待付款、待发货、待收货、已完成等订单信息。"
          },
          {
            img: require("@/assets/images/swiper/payment/4-1.jpg"),
            icon: require("@/assets/images/swiper/payment/4-2.jpg"),
            title: "我要卖货",
            content:
              "出品商家个人主页我的购买当中可显示待付款、代发货、已完成等订单信息"
          },
          {
            img: require("@/assets/images/swiper/payment/5-1.jpg"),
            icon: require("@/assets/images/swiper/payment/5-2.jpg"),
            title: "个人余额",
            content: "订单完成后，交易金额将归纳商家账户余额。"
          }
        ],
        analyze: [
          {
            img: require("@/assets/images/swiper/analyze/1-1.jpg"),
            icon: require("@/assets/images/swiper/analyze/1-2.jpg"),
            title: "查行情",
            content:
              "我的主页点击查行情，了解更多品牌行情。所有贸易数据都可以被详细查询。"
          },
          {
            img: require("@/assets/images/swiper/analyze/1-1.jpg"),
            icon: require("@/assets/images/swiper/analyze/1-2.jpg"),
            title: "交易数据",
            content:
              "往期数据回顾，在此界面均能查看，点击品牌品类搜索，了解更多商品行情。"
          }
        ],
        merchant: [
          {
            img: require("@/assets/images/swiper/merchant/1-1.jpg"),
            icon: require("@/assets/images/swiper/merchant/1-2.jpg"),
            title: "商家地图",
            content: "打开软件定位权限，随时随地查找附近商家。"
          },
          {
            img: require("@/assets/images/swiper/merchant/2-1.jpg"),
            icon: require("@/assets/images/swiper/merchant/2-2.jpg"),
            title: "搜索同行",
            content: "点击搜索框，选择商家分类即可快速找到商家。"
          },
          {
            img: require("@/assets/images/swiper/merchant/3-1.jpg"),
            icon: require("@/assets/images/swiper/merchant/3-2.jpg"),
            title: "商家名片",
            content: "商家店铺详情信息一览，联系方式一键复制即可。"
          },
          {
            img: require("@/assets/images/swiper/merchant/4-1.jpg"),
            icon: require("@/assets/images/swiper/merchant/4-2.jpg"),
            title: "申请入驻",
            content: "未认证商家点击右上角申请入驻，填写详情信息即可入住APP"
          }
        ]
      },
      swiperOptions: {
        pagination: {
          el: ".page-swiper-pagination"
        },
        navigation: {
          nextEl: ".page-swiper-navigation--next",
          prevEl: ".page-swiper-navigation--prev"
        }
      },
      renderData: null
    };
  },
  mounted() {
    const type = this.$route.params.type;
    this.renderData = this.datas[type];
  }
};
</script>

<style></style>

<template>
  <div class="auction">
    <div class="help-img"><img :src="headImg" @load="onLoadImg" width="100%" alt=""></div>
    <div class="auction-title">帮帮虎包品联展</div>
    <div class="auction-imgLists">
      <div>
        <div class="auction-imgTitle">二奢商家聚集地</div>
        <div class="auction-imgMsg">
          <div>国内领先的二手奢侈包品</div>
          <div>展，汇聚国内二奢行业精</div>
          <div>英，是业内专业又全面的</div>
          <div>采购平台。</div>
        </div>
        <div class="auction-imgContent">
          <div>展会集商业交流、互动展示以及学术</div>
          <div>学习为一体，提供全面高效的贸易交</div>
          <div>流平台，帮助商家掌握行业最新动态</div>
          <div>为目的，致力于打造业内最高效和专</div>
          <div>业的交流展会。</div>
        </div>
      </div>
      <div class="auction-img"><img src="~@/assets/images/auction/组 2@2x.png" width="100%" alt=""></div>
    </div>
    <div class="auction-imgLists">
      <div class="auction-img1 auction-imgRight">
        <img src="~@/assets/images/auction/组 4@2x.png" width="100%" alt="">
        <div class="auction-imgTip">全国优质展商</div>
      </div>
      <div class="auction-img1 auction-imgRight">
        <img src="~@/assets/images/auction/组 6@2x.png" width="100%" alt="">
        <div class="auction-imgTip">汇集全国高端买手</div>
      </div>
      <div class="auction-img1">
        <img src="~@/assets/images/auction/组 9@2x.png" width="100%" alt="">
        <div class="auction-imgTip">帮助二奢线上直播</div>
      </div>
    </div>
    <div class="auction-title1">CTA竞价会</div>
    <div class="auction-content">
      <div>China Top Auction是帮帮虎举办的国内领先的二手奢侈品竞价会。汇集全国商家的名表、首饰、箱包</div>
      <div>等精品。打造高效快速竞价模式，为同行提供优质竞价服务，帮助商家快速获取优质货品。 </div>
    </div>
    <div class="auction-bottomImg">
      <div class="auction-imgBox">
        <img src="~@/assets/images/auction/组 11@2x.png" width="100%" alt="">
      </div>
    </div>
    <div class="auction-bottom"></div>
  </div>
</template>

<script>
export default {
  data(){
      return {
          headImg:'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/zhanhui.jpg?x-oss-process=image/resize,p_30'
      }
  },
  methods:{
      onLoadImg(){
          this.headImg = 'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/zhanhui.jpg'
      }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- <div class="service">
        <div class="service-title">服务 I 商家成长</div>
        <div>中国检验认证集团“国”字头第三方奢侈品鉴定培训机构，</div>
        <div>联合帮帮虎致力于以独立的第三方身份，为行业提供公正、诚信的奢侈品鉴定及培训服务。</div>
    </div> -->
    <div class="help-img"><img :src="headImg" @load="onLoadImg" width="100%" alt=""></div>
    <div class="college-gray">
        <div class="erp">
            <div class="erp-img1">
                <img src="~@/assets/images/appraisal/组 193@2x.png" width="100%" alt="">
                <div class="erp-msg">
                    <div>提供库存管理的全面解决方案，囊括仓库管理、销售管</div>
                    <div>理、客户管理、财务中心等诸多功能，帮助企业改善服</div>
                    <div>务质量、节省订货费用、降低生产成本，提高人员和设</div>
                    <div>备利用率</div>
                </div>
            </div>
            <div class="erp-img2"><img src="~@/assets/images/appraisal/组 206@2x.png" width="100%" alt=""></div>
        </div>
        <div><img src="~@/assets/images/appraisal/组 540@2x.png" width="100%" alt=""></div>
        <div class="erp-title">帮助商家经营</div>
        <div><img src="~@/assets/images/appraisal/组 541@2x.png" width="73%" alt=""></div>
        <div class="erp-downLoad">
            <div class="erp-downLoadImg">
                <img src="~@/assets/images/appraisal/组 225@2x.png" width="100%" alt="">
                <div class="erp-downLoadMsg">您的专属二奢管家</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            headImg:'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/erp.jpg?x-oss-process=image/resize,p_30'
        }
    },
    methods:{
        onLoadImg(){
            this.headImg = 'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/erp.jpg'
        }
    }
}
</script>

<style scoped>

</style>
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
// import Index from "@/views/Index.vue";
import TradeApp from "@/views/TradeApp.vue";
import transactionApp from "@/views/transactionApp.vue";
import exhibition from "@/views/exhibition.vue";
import auction from "@/views/auction.vue";
import appraisal from "@/views/appraisal.vue";
import butler from "@/views/butler.vue";
import Detail from "@/views/Detail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: TradeApp,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  // {
  //   path: "/index",
  //   name: "index",
  //   component: index,
  //   meta: {
  //     title: "江苏帮帮虎网络有限公司"
  //   }
  // },
  {
    path: "/transactionApp",
    name: "transactionApp",
    component: transactionApp,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  {
    path: "/exhibition",
    name: "exhibition",
    component: exhibition,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  {
    path: "/auction",
    name: "auction",
    component: auction,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  {
    path: "/appraisal",
    name: "appraisal",
    component: appraisal,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  {
    path: "/butler",
    name: "butler",
    component: butler,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  {
    path: "/functions/:type",
    name: "Detail",
    component: Detail,
    meta: {
      title: "江苏帮帮虎网络有限公司"
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
    window.scrollTo(0,0);
  }
  next();
});

export default router;

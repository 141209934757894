<template>
  <div>
    <!-- <div>
      <div class="help-img help-imgBottom">
        <img
          width="100%"
          :src="headImg"
          @load="onLoadImg"
          alt=""
          srcset=""
        />
      </div>
      <div class="tradeApp">
        <div style="width: 50%" class="tradeApp-trade">
          <div class="tradeApp-tradeDiv">
            <div class="tradeDiv-title">帮助 I 商家贸易</div>
            <div class="tradeDiv-msg">帮帮虎贸易APP</div>
            <div>帮帮虎创新传统电商模式，强化移动互联网直播</div>
            <div>运营和知识内容分享。</div>
            <div>以去中心化平台定位，服务商家交易：严格的商</div>
            <div>家判定标准、更公平的竞价交易机制、完善的交</div>
            <div>易管理和尽心高效的客服沟通等流程体验。</div>
            <div></div>
          </div>
        </div>
        <img width="50%" src="~@/assets/images/TradeApp/组 72@2x.png" alt="" />
      </div>
      <div class="tradeApp">
        <img width="50%" src="~@/assets/images/TradeApp/组 65@2x.png" alt="" />
        <div style="width: 50%" class="tradeApp-trade tradeColor">
          <div class="tradeApp-tradeDiv divLeft">
            <div class="tradeDiv-title">链接 I 商家交流</div>
            <div class="tradeDiv-msg">帮帮虎包品联展</div>
            <div>帮帮虎包品联展是国内领先的二手奢侈包品展，</div>
            <div>汇聚国内闲置奢侈品行业精英，是业内专业又全</div>
            <div>面的采购平台。展会集商业交流、互动展示以及</div>
            <div>学术学习为一体，提供全面高效的贸易交流平</div>
            <div>台，帮助商家掌握行业最新动态为目的，致力于</div>
            <div>打造业内最高效和专业的交流展会。</div>
          </div>
        </div>
      </div>
      <div class="tradeApp">
        <div style="width: 50%" class="tradeApp-trade colorGray">
          <div class="tradeApp-tradeDiv">
            <div class="tradeDiv-title">提效 I 商家出品</div>
            <div class="tradeDiv-msg">帮帮虎竞价会</div>
            <div>帮帮虎竞价会是国内领先的二手奢侈品竞价会，</div>
            <div>拥有正规竞价资质和资深竞价师，拍品汇集全国</div>
            <div>商家的名表、首饰、箱包等精品。致力于打造高</div>
            <div>效竞价模式，为同行提供优质竞价服务，帮助商</div>
            <div>家快速获取优质货品。 </div>
          </div>
        </div>
        <img width="50%" src="~@/assets/images/TradeApp/组 70@2x.png" alt="" />
      </div>
      <div class="tradeApp">
        <img width="50%" src="~@/assets/images/TradeApp/组 68@2x.png" alt="" />
        <div style="width: 50%" class="tradeApp-trade colorBlue">
          <div class="tradeApp-tradeDiv divLeft">
            <div class="tradeDiv-title msgTop">服务 I 商家成长</div>
            <div class="tradeDiv-msg">中检帮帮虎鉴定培训</div>
            <div>2016年6月成立同道鉴定商学院，2021年与中检</div>
            <div>集团四川公司签订战略协议，更名为中检帮帮虎</div>
            <div>商学院。为社会各界提供公正诚信的第三方奢侈</div>
            <div>品鉴定与培训服务，为奢侈品市场保驾护航。</div>
            <div class="divTop">在中国经济与闲置文化崛起之时，中检帮帮虎坚</div>
            <div>持“帮你成长，帮你向前”的理念，成为进入二奢</div>
            <div>深度整合行业资源的高端学习平台。至今已完成</div>
            <div>3000+学员的鉴定培训，帮助他们快速进入二奢</div>
            <div>行业。</div>
          </div>
        </div>
      </div>
      <div class="tradeApp">
        <div style="width: 50%" class="tradeApp-trade colorPink">
          <div class="tradeApp-tradeDiv">
            <div class="tradeDiv-title msgTop2">赋能 I 商家经营</div>
            <div class="tradeDiv-msg">帮帮虎管货ERP</div>
            <div>面向二奢商家的库存管理平台，实现采购、销</div>
            <div>售、库存、资金全链路管理，数据赋能，助力企</div>
            <div>业降本增效。</div>
          </div>
        </div>
        <img width="50%" src="~@/assets/images/TradeApp/组 75@2x.png" alt="" />
      </div>
      <div class="major">
        <div style="width: 50%" class="major-content">
          <div class="content">
              <div class="content-title">行业专业度</div>
              <div>专业一直是帮帮虎的坚持，也是我们吸引众</div>
              <div>同行的根本原因。多项业务的协调发展，为</div>
              <div>同行提供高效的贸易频率、交流平台、发声</div>
              <div>渠道，专注帮助全国商家高效贸易，坚持创</div>
              <div>新，永不过时。</div>
              <div class="content-part">战略合作伙伴（部分）</div>
              <img class="content-img" src="~@/assets/images/TradeApp/组 26@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 34@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/资源 24.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/资源 25.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/资源 26.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 29@2x.png" alt="">
          </div>
        </div>
        <div width="50%" class="major-content">
          <div class="content content-left">
              <div class="content-title">长期伴随性</div>
              <div>多年来，我们拥有了稳健发展的粉丝商家群体，</div>
              <div>随着平台的稳步发展，他们也与平台共同成长，</div>
              <div>许多商家在帮帮虎平台从初从业到业内的中坚力</div>
              <div>量甚至领航者。帮帮虎与他们之间，除了线上线</div>
              <div>下的交流贸易互动，还多了一层感情维系。</div>
              <div class="content-part">同行合作伙伴（部分）</div>
              <img class="content-img" src="~@/assets/images/TradeApp/组 36@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 50@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 63@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 60@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 41@2x.png" alt="">
              <img class="content-img" src="~@/assets/images/TradeApp/组 47@2x.png" alt="">
          </div>
        </div>
      </div>
      <div class="downLoad">
          <div class="downLoad-title">立即下载帮帮虎</div>
          <a class="downLoad-btn" target="_blank" href="https://android.myapp.com/myapp/detail.htm?apkName=com.bbh">下载帮帮虎</a>
      </div>
    </div> -->
    <transactionApp v-show="this.$store.state.tabIndex==='1'"></transactionApp>
    <exhibition v-show="this.$store.state.tabIndex==='2'"></exhibition>
    <appraisal v-show="this.$store.state.tabIndex==='3'"></appraisal>
    <auction v-show="this.$store.state.tabIndex==='4'"></auction>
    <butler v-show="this.$store.state.tabIndex==='5'"></butler>
  </div>
</template>

<script>
import transactionApp from "@/views/transactionApp.vue";
import exhibition from "@/views/exhibition.vue";
import appraisal from "@/views/appraisal.vue";
import auction from "@/views/auction.vue";
import butler from "@/views/butler.vue";
export default {
  components:{
    transactionApp,
    exhibition,
    appraisal,
    auction,
    butler
  },
  data(){
      return {
          headImg:'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/def.jpg?x-oss-process=image/resize,p_30'
      }
  },
  methods:{
      onLoadImg(){
          this.headImg = 'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/def.jpg'
      }
  }
};
</script>

<style scoped>
</style>
<template>
  <div>
    <div class="help-img"><img :src="headImg" @load="onLoadImg" width="100%" alt=""></div>
    <div class="fastTrade imgTop">
      <div class="help-imgs"><img src="~@/assets/images/transactionApp/组 527@2x.png" width="73%" alt=""></div>
    </div>
    <div class="fastTrade">
      <div class="fastTrade-title">帮助 I 商家贸易</div>
      <div class="fastTradeList">
        <div class="fastTrade-img">
          <div class="imgTitle">帮帮虎APP</div>
          <div class="imgContent1">解决全国供需关系</div>
          <div class="imgContent2">拓展更多销售渠道</div>
        </div>
        <div class="fastTrade-img2">
          <div class="imgTitle">帮帮虎包展</div>
          <div class="imgContent1">解决全国货品流通</div>
          <div class="imgContent2">高效交流无信息差</div>
        </div>
        <div class="fastTrade-img3">
          <div class="imgTitle">CTA竞价会</div>
          <div class="imgContent1">解决高价货品流通</div>
          <div class="imgContent2">高效匹配货值稳定</div>
        </div>
      </div>
      <div class="fastTradeList">
        <div class="fastTrade-img4">
          <div class="imgTitle">管货ERP</div>
          <div class="imgContent1">解决经营货品管理</div>
          <div class="imgContent2">精准掌握贸易风险</div>
        </div>
        <div class="fastTrade-img5">
          <div class="imgTitle">鉴定中心</div>
          <div class="imgContent1">解决经营鉴定疑惑</div>
          <div class="imgContent2">获得货品流通背书</div>
        </div>
        <div class="fastTrade-img6">
          <div class="imgTitle">帮帮虎培训</div>
          <div class="imgContent1">解决行业入门途径</div>
          <div class="imgContent2">快速获得二奢资源</div>
        </div>
      </div>
      <div class="downLoadQRCode">
        <div class="QRCodeImg"><img src="~@/assets/images/transactionApp/组 105@2x.png" width="100%" alt=""></div>
        <div class="QRCodeTitle">做二奢就用帮帮虎</div>
        <div class="QRCodeContent">正品保障·商家认证·无忧售后·成色标准</div>
        <a class="QRCodeBtn btn1" target="_blank" href="https://sj.qq.com/appdetail/com.bbh">下载帮帮虎</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
        return {
            headImg:'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/shouye.jpg?x-oss-process=image/resize,p_30'
        }
    },
    methods:{
        onLoadImg(){
            this.headImg = 'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/shouye.jpg'
        }
    }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div class="Empowerment">
        <div class="help-img"><img :src="headImg" @load="onLoadImg" width="100%" alt=""></div>
        <div class="team">
            <div class="team-imgList team-imgListBottom">
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 151@2x.png" width="100%" alt="">
                    <div>章寅锋 Tiger</div>
                </div>
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 148@2x.png" width="100%" alt="">
                    <div>曾涛</div>
                </div>
                <div class="team-img">
                    <img src="~@/assets/images/bulter/组 146@2x.png" width="100%" alt="">
                    <div>李岚迪</div>
                </div>
            </div>
            <div class="team-imgList team-imgListBottom">
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 158@2x.png" width="100%" alt="">
                    <div>胡志彬</div>
                </div>
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 156@2x.jpg" width="100%" alt="">
                    <div>孙曦</div>
                </div>
                <div class="team-img">
                    <img src="~@/assets/images/bulter/组 154@2x.png" width="100%" alt="">
                    <div>葛松</div>
                </div>
            </div>
            <div class="team-imgList team-imgListBottom">
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 167@2x.png" width="100%" alt="">
                    <div>顾永菲</div>
                </div>
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 162@2x.png" width="100%" alt="">
                    <div>许哲睿</div>
                </div>
                <div class="team-img">
                    <img src="~@/assets/images/bulter/组 164@2x.png" width="100%" alt="">
                    <div>钟凯强</div>
                </div>
            </div>
            <div class="team-imgList">
                <div class="team-img team-imgRight">
                    <img src="~@/assets/images/bulter/组 169@2x.png" width="100%" alt="">
                    <div>陈婷</div>
                </div>
                <div class="team-img team-imgRight">
                </div>
                <div class="team-img"></div>
            </div>
        </div>
        <div class="bottomBlack"></div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            headImg:'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/tuiduan.jpg?x-oss-process=image/resize,p_30'
        }
    },
    methods:{
        onLoadImg(){
            this.headImg = 'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/tuiduan.jpg'
        }
    }
}
</script>

<style scoped>

</style>
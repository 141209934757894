<template>
  <div class="page-footer">
    <div class="footer-inner">
      <div class="focus-list">
        <div class="title">关注我们</div>
        <ul class="list"  >
          <li class="item" v-for="(brand, index) in brands" :key="index">
            <a target="_blank" :href="brand.link" v-if="brand.type === 'img'">
              <img class="icon" :src="brand.src" alt="" />
            </a>
            <div class="sign-hover" v-else>
              <img class="icon" :src="brand.src" alt="" @click="block" />
              <img v-if="isblock" class="icon_hover" @click="isclose" :src="brand.link" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <p class="icp-info">
        Copyright © {{ currentYear }} 江苏帮帮虎网络有限公司 技术支持:
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >江苏帮帮虎网络有限公司 苏ICP备19011839号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      brands: [
        {
          src: require("@/assets/images/wx.png"),
          link: require("@/assets/images/qr.jpg"),
          title: "微信",
          type: "hover"
        },
        {
          src: require("@/assets/images/wb.png"),
          link:
            "https://weibo.com/p/1005057404083858/home?from=page_100505&mod=TAB&is_all=1",
          title: "微博",
          type: "img"
        },
        {
          src: require("@/assets/images/sh.png"),
          link:
            "https://mp.sohu.com/profile?xpt=ODg0MjEwNTEtZGRkNy00OTBhLTk3MGMtNmUwNzg0MDViYzhj&_f=index_pagemp_2&spm=smpc.content.author.3.1584501955435JMoATOy",
          title: "搜狐",
          type: "img"
        },
        {
          src: require("@/assets/images/du.png"),
          link:
            "https://baike.baidu.com/item/%E5%B8%AE%E5%B8%AE%E8%99%8E/23749384",
          title: "百度",
          type: "img"
        },
        {
          src: require("@/assets/images/yk.png"),
          link:
            "https://i.youku.com/i/UNzY5NTg0ODYyMA==?spm=a2h0k.11417342.0.0",
          title: "优酷",
          type: "img"
        }
      ],
      currentYear: new Date().getFullYear(),
      isblock: false,
    };
  },
  methods:{
    block(){
      this.isblock = true
    },
    isclose(){
      this.isblock = false
    }
  }
};
</script>

<style></style>

<template>
  <div class="page-header">
    <div>
      <el-menu style="background: #fff;" :default-active="activeIndex" class="el-menu-demo headHeight" :active-text-color="textColor" mode="vertical" @select="handleSelect">
        <div style="margin-right:100px"><img src="~@/assets/images/new/组 94.svg" alt="" width="100%"></div>
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">APP</el-menu-item>
        <el-menu-item index="3">ERP</el-menu-item>
        <el-menu-item index="4">展会</el-menu-item>
        <el-menu-item index="5">团队</el-menu-item>
        <a
          class="download-bt"
          target="_blank"
          href="https://android.myapp.com/myapp/detail.htm?apkName=com.bbh"
        ></a>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: '1',
      textColor: '#ff9314'
    }
  },
  methods: {
    handleSelect(key) {
      this.$store.state.tabIndex = key
      document.body.scrollTop = document.documentElement.scrollTop = 0
      // if(key === '0'){
      //   this.$router.push('/')
      // }
      // else if(key === '1'){
      //   this.$router.push('/transactionApp')
      // }
      // else if(key === '2'){
      //   this.$router.push('/exhibition')
      // }
      // else if(key === '3'){
      //   this.$router.push('/appraisal')
      // }
      // else if(key === '4'){
      //   this.$router.push('/auction')
      // }
      // else if(key === '5'){
      //   this.$router.push('/butler')
      // }
    }
  }
};
</script>

<style scoped>
.el-menu-item:hover{
  background: none;
}
.el-menu{
  background: none;
}
.el-menu-item{
  font-size: 17px;
  font-weight: 700;
}
.el-menu-item:focus{
  background: none;
}
</style>

<template>
  <div>
    <div class="help-img"><img :src="headImg" @load="onLoadImg" width="100%" alt=""></div>
    <div class="exhibition-times imgTop">
        <div><img src="~@/assets/images/exhibition/组 534@2x.png" width="73%" alt=""></div>
        <div class="app-title">帮助商家经营</div>
        <div class="app-content">帮帮虎以服务全球闲置品商家为目标，不断探索及创新，用心解决商家日常经营中所遇到的问题，</div>
        <div class="app-content">提供高效解决方案。为更多的奢侈品爱好者提供更便利的交流渠道，致力于打造一个诚信、高效的高端</div>
        <div class="app-content">闲置品B2B流通平台。</div>
        <div class="app-imgList">
            <div class="app-imgs">
                <img src="~@/assets/images/exhibition/组 448@2x.png" width="100%" alt="">
                <div class="app-imgMsg">直播竞价</div>
            </div>
            <div class="app-imgs">
                <img src="~@/assets/images/exhibition/组 535@2x.png" width="100%" alt="">
                <div class="app-imgMsg">行情数据</div>
            </div>
            <div class="app-imgs">
                <img src="~@/assets/images/exhibition/组 434@2x.png" width="100%" alt="">
                <div class="app-imgMsg">DRAMA直播</div>
            </div>
        </div>
        <div><img src="~@/assets/images/exhibition/组 536@2x.png" width="73%" alt=""></div>
        <div class="app-title titleTop">什么是帮帮虎商家地图？</div>
        <div class="app-content">在全国每一座城市里，都有这些精品二奢店。他们无一不拥有全面的二奢经营之道，以及对二奢行业的热忱。出于对</div>
        <div class="app-content titleBottom">帮帮虎品牌的认可，他们和帮帮虎拥有相同的二奢认识，引导二奢风尚的合作。并享受帮帮虎带来的品牌成长。</div>
        <div><img src="~@/assets/images/exhibition/组 537@2x.png" width="73%" alt=""></div>
        <div class="app-QRCode">
            <div class="app-QRCodeTitle">为什么要成为帮帮虎二奢商家?</div>
            <div class="app-QRCodeTop">帮帮虎是一家全球化的二奢平台服务商。我们正在重新定义二奢贸易和交流的意义。</div>
            <div class="app-QRCodeTop">我们拥有独立的设计团队，客服团队，市场团队，以及研发团队。拥有强有力的诚信体</div>
            <div>系，来维护二奢商家的权益。帮帮虎每年组织各地城市线下展会，每位城市二奢商家都</div>
            <div>能享受帮帮虎全渠道的引流和曝光。</div>
            <div class="app-QRCodeTop">如果您经营二奢项目，乐于分享二奢可以联系我们，我们乐于与您合作，帮您快速链接</div>
            <div>全国商家。我们致力于让每位二奢伙伴能和帮帮虎共同分享成长的快乐!</div>
            <div class="app-QRCodeImgList">
                <div class="app-QRCodeImg app-QRCodeImgRight">
                    <img src="~@/assets/images/exhibition/组 539@2x.png" width="100%" alt="">
                    <div class="app-QRCodeMsg">长按识别 | 咨询详情</div>
                </div>
                <div class="app-QRCodeImg">
                    <img src="~@/assets/images/exhibition/组 538@2x.png" width="100%" alt="">
                    <div class="app-QRCodeMsg">长按识别 | 下载APP</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            headImg:'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/app.jpg?x-oss-process=image/resize,p_30'
        }
    },
    methods:{
        onLoadImg(){
            this.headImg = 'https://pc-main-work.oss-cn-shanghai.aliyuncs.com/headImgs/app.jpg'
        }
    }
}
</script>

<style scoped>

</style>